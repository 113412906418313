'use strict';

/**
 * Collects 'pid' values from elements with the class 'swiper-slide' and sends
 * an AJAX request to fetch legal data. If the request is successful, it updates
 * the HTML of the '.home-legals' container with the fetched data.
 */
function loadLegalData() {
    var pids = [];

    $('.swiper-slide').each(function () {
        var pid = $(this).data('pid');
        if (pid !== undefined && pid !== null && pid !== '') {
            pids.push(pid);
        }
    });

    if (pids.length > 0) {
        $.ajax({
            url: $('#legalsURL').val(),
            method: 'get',
            data: pids.join(','),
            success: function (data) {
                $('.home-legals').html(data);
            }
        });
    }
}

$(loadLegalData);
